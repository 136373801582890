const fullHostname = window.location.hostname;
const hostname = fullHostname.split(".").slice(-2).join(".");

let apiUrl;

if (fullHostname === "localhost" || fullHostname === "10.10.0.41") {
  apiUrl = `http://10.10.0.32:8080/api`;
} else {
  apiUrl = `https://account-api.${hostname}/api`;
}

export { apiUrl };
