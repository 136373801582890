import React, { useContext } from "react";
import Bottom from "../element/bottom";
import Header1 from "../layout/header1";
import { LanguageContext } from "../../languagesContext";
import { languages } from "../../languages";
const Privacy = () => {
  const { language } = useContext(LanguageContext);
  const selectedLanguage = languages[language];
  return (
    <div>
      <Header1 activeItem="privacy-policy"></Header1>
      {/* <div className="intro" id="home">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-6 col-lg-6 col-12">
              <div className="intro-content">
                <h1>{selectedLanguage.privacy.privacyPolicty}</h1>
                <p>{selectedLanguage.aboutUs.content}</p>
              </div>

              <div className="intro-btn">
                <Link to={"/signup"} className="btn btn-secondary">
                  Get Started
                </Link>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-12">
              <img src={pic} alt="main" style={{ maxWidth: "600px" }} />
            </div>
          </div>
        </div>
      </div> */}
      <div className="intro" id="home">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 policy">
              <h1>{selectedLanguage.aboutUs.backgroundTitle}</h1>
              <p>{selectedLanguage.aboutUs.backgroundContent}</p>
            </div>
            <div className="col-12 policy">
              <h1>{selectedLanguage.aboutUs.whatWeCollectTitle}</h1>
              <p>{selectedLanguage.aboutUs.whatWeCollectContent}</p>
            </div>
            <div className="col-12 policy">
              <h1>{selectedLanguage.aboutUs.whenWeuseTitle}</h1>
              <ul>
                {selectedLanguage.aboutUs.whenWeuseContent.map(
                  (content, index) => (
                    <li key={index} style={{ listStyle: "inside" }}>
                      {content}
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="col-12 policy">
              <h1>{selectedLanguage.aboutUs.yourRightsTitle}</h1>
              <ul>
                {selectedLanguage.aboutUs.yourRightsContent.map(
                  (content, index) => (
                    <li key={index} style={{ listStyle: "inside" }}>
                      {content}
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="col-12 policy">
              <h1>{selectedLanguage.aboutUs.howToContactTitle}</h1>
              <ul>
                {selectedLanguage.aboutUs.howToContactContent.map(
                  (content, index) => (
                    <li key={index} style={{ listStyle: "inside" }}>
                      {content}
                    </li>
                  )
                )}
                <p type="email" style={{fontSize:"20px",color:"white"}}>{selectedLanguage.aboutUs.howToContact}</p>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Bottom />
    </div>
  );
};
export default Privacy;
