import React, { useState, useEffect, useContext } from "react";
import { apiUrl } from "../../config";
import { toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";
import Header1 from "../layout/header1";
import { languages } from "../../languages";
import { LanguageContext } from "../../languagesContext";
function Signup() {
  const [registerData, setRegisterData] = useState({});
  const [countryCode, setCountryCode] = useState();
  const [currencyId, setCurrencyId] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const { language } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const selectedLanguage = languages[language];
  const [formData, setFormData] = useState([
    {
      id: "email",
      label: selectedLanguage.rest.Email,
      placeholder: selectedLanguage.rest.Email,
      type: "text",
      error: "",
      value: "",
    },
    {
      id: "password",
      label: selectedLanguage.rest.Password,
      placeholder: selectedLanguage.rest.Password,
      type: "password",
      error: "",
      value: "",
    },
    {
      id: "last_name",
      label: selectedLanguage.rest.LastName,
      placeholder: selectedLanguage.rest.LastName,
      type: "text",
      error: "",
      value: "",
    },
    {
      id: "first_name",
      label: selectedLanguage.rest.FirstName,
      placeholder: selectedLanguage.rest.FirstName,
      type: "text",
      error: "",
      value: "",
    },
    {
      id: "address",
      label: selectedLanguage.rest.Address,
      placeholder: selectedLanguage.rest.Address,
      type: "text",
      error: "",
      value: "",
    },
    {
      id: "postal_code",
      label: selectedLanguage.rest.PostalCode,
      placeholder: selectedLanguage.rest.PostalCode,
      type: "number",
      error: "",
      value: "",
    },
    {
      id: "phone",
      label: selectedLanguage.rest.Phone,
      placeholder: selectedLanguage.rest.Phone,
      type: "number",
      error: "",
      value: "",
    },
    {
      id: "promo_code",
      label: selectedLanguage.rest.PromoCode,
      placeholder: selectedLanguage.rest.PromoCode,
      type: "number",
      error: "",
      value: "0",
    },
  ]);
  const history = useHistory();
  useEffect(() => {
    const myHeaders = new Headers();
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${apiUrl}/register-data`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setRegisterData(result);
      })
      .catch((error) => console.error(error));
  }, []);
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevState) => {
      return prevState.map((item) => {
        if (item.id === id) {
          return { ...item, value: value, error: "" };
        }
        return item;
      });
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = formData.filter((item) => item.value === "");
    if (errors.length > 0) {
      setFormData(
        formData.map((item) => ({
          ...item,
          error: errors.includes(item) && "Field is required",
        }))
      );
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const getValueWIthId = (id) => {
      return formData.find((item) => item.id === id).value;
    };
    const raw = JSON.stringify({
      email: getValueWIthId("email"),
      password: getValueWIthId("password"),
      last_name: getValueWIthId("last_name"),
      first_name: getValueWIthId("first_name"),
      postal_code: getValueWIthId("postal_code"),
      phone: getValueWIthId("phone"),
      ref_country_id: countryCode,
      currency: currencyId,
      agree: true,
      address: getValueWIthId("address"),
      promo_code: getValueWIthId("promo_code") || 0,
      security_question: "dumb",
      security_answer: "answer",
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setLoading(true);
    fetch(`${apiUrl}/register`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        if (result.token) {
          toast.success("Registered");
          history.push("../signin");
        } else if (result.error) {
          setErrorMessage(result.error);
          return;
        } else if (result.data) {
          setErrorMessage(result.data);
          return;
        } else {
          setErrorMessage(result);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setErrorMessage("Something went wrong");
      });
  };
  return (
    <>
      <Header1 activeItem="signup" />
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="mini-logo text-center my-5">
                <Link to={"./"}>
                  <img
                    src={require("../../images/logo.png")}
                    style={{ maxWidth: "120px" }}
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="auth-form card">
                <div className="card-header justify-content-center">
                  <h4 className="card-title">{selectedLanguage.rest.SignUp}</h4>
                </div>
                <div className="card-body">
                  <form className="signup_validate">
                    {formData &&
                      formData.map((item) => (
                        <div className="mb-3" key={item.label}>
                          <label>{item.label}</label>
                          <input
                            type={item.type}
                            className="form-control"
                            placeholder={item.placeholder}
                            name={item.id}
                            onChange={handleInputChange}
                            id={item.id}
                          />
                          <p style={{ color: "#bf6159" }}>{item.error}</p>
                        </div>
                      ))}
                    <div style={{ margin: "15px 0px" }}>
                      <select
                        className="form-control"
                        onChange={(event) => setCountryCode(event.target.value)}
                      >
                        <option>{selectedLanguage.rest.Country}</option>
                        {registerData.countries ? (
                          registerData.countries.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))
                        ) : (
                          <option>{selectedLanguage.rest.Country}</option>
                        )}
                      </select>
                    </div>
                    <div>
                      <select
                        className="form-control"
                        onChange={(event) => setCurrencyId(event.target.value)}
                      >
                        <option>{selectedLanguage.rest.Currency}</option>
                        {registerData.currencies ? (
                          registerData.currencies.map((item) => (
                            <option key={item.id} value={item.code}>
                              {item.title}
                            </option>
                          ))
                        ) : (
                          <option>{selectedLanguage.rest.Currency}</option>
                        )}
                      </select>
                    </div>
                    <div className="text-center mt-4">
                      <button
                        className="btn btn-success btn-block"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {loading
                          ? selectedLanguage.rest.Loading
                          : selectedLanguage.rest.SignUp}
                      </button>
                    </div>
                  </form>
                  <div className="new-account mt-3">
                    <p>
                      {selectedLanguage.rest.AllreadyHave}
                      <Link className="text-primary" to={"signin"}>
                        {" "}
                        {selectedLanguage.rest.SignIn}
                      </Link>
                    </p>
                    <p style={{ color: "#bf6159" }}>{errorMessage}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
