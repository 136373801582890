import React from "react";
import Header1 from "../layout/header1";
import PricingPlans from "./PricingPlans";
import Footer1 from "../layout/footer1";
import Bottom from "../element/bottom";
const Pricing = ()=>{
    return (
        <>
        <Header1 activeItem="pricing"/>
        <PricingPlans />

        <Bottom />
        <Footer1 />
        </>
    )
}
export default Pricing