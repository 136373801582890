import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { languages } from '../../languages';
import { LanguageContext } from '../../languagesContext';


function Bottom() {
    const { language } = useContext(LanguageContext);
    const selectedLanguage = languages[language]
    return (
        <>
            <div className="bottom section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <div className="bottom-logo">
                                <img className="pb-3" style={{width:'170px'}} src={require("../../images/logo.png")} alt="" />
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <div className="bottom-widget">
                                <ul>
                                    <li><Link to={"/"}>{selectedLanguage.landing.Home}</Link></li>
                                    <li><Link to={"/pricing"}>{selectedLanguage.rest.Pricing}</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <div className="bottom-widget">
                                <ul>
                                    <li><Link to={"/signin"}>{selectedLanguage.rest.SignIn}</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <div className="bottom-widget">
                                <ul>
                                    <li><Link to={"/signup"}>{selectedLanguage.rest.SignUp}</Link></li>
                                </ul>
                            </div>
                        </div>
  
                    </div>
                </div>
            </div>

        </>
    )
}

export default Bottom;