import React, { useContext, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { languages } from "../../languages";
import { LanguageContext } from "../../languagesContext";
const PricingPlans = () => {
  const [activeContainer, setActiveContainer] = useState("standard");

  const switchToStandard = () => {
    setActiveContainer("standard");
  };

  const switchToAdvanced = () => {
    setActiveContainer("advanced");
  };
  const { language } = useContext(LanguageContext);
  const selectedLanguage = languages[language]
  const data = {
    English: {
      plans: {
        basic: [
          {
            name: "Basic",
            price: "$ 250 - $ 5000",
            features: [
              "3 trading and educational sessions with senior analyst",
              "Access to 100+ assets"
             
            ],
          },
          {
            name: "Trader",
            price: "$ 5001 - $ 15000",
            features: [
              "10 trading and educational sessions with senior analyst",
              "Access to 100+ assets",
              "3 VIP signals",
              "First withdrawal for free"
            ],
          },
          {
            name: "Silver",
            price: "$ 15001 - $ 50000",
            features: [
              "Unlimited trading and educational sessions with senior analyst",
              "Access to 200+ assets",
              "5 VIP signals",
              "5 withdrawals for free"
            ],
          },
        ],
        advanced: [
          {
            name: "Gold",
            price: "$ 50001 - $ 150000",
            features: [
              "Unlimited trading and educational sessions with senior analyst",
              "Access to 200+ assets",
              "5 VIP signals weekly",
              "10 withdrawals for free"
            ],
          },
          {
            name: "Platinum",
            price: "$ 150001 - $ 500000",
            features: [
              "Unlimited trading and educational sessions with senior analyst",
              "Access to 200+ assets",
              "10 VIP signals weekly",
              "Unlimited free withdrawals"
            ],
          },
          {
            name: "Diamond",
            price: "$ 500001 - $ 1000000",
            features: [
              "Unlimited trading and educational sessions with senior analyst",
              "Access to 200+ assets",
              "Unlimited VIP signals",
              "Unlimited free withdrawals",
              "10 express withdrawals for free",
              "Higher leverages on all the assets",
              "Financial advisor services"
            ],
          },
        ],
      },
    },
    French: {
      "plans": {
        "basic": [
          {
            "name": "Basique",
            "price": "250 $ - 5000 $",
            "features": [
              "3 sessions de trading et d'éducation avec un analyste principal",
              "Accès à plus de 100 actifs"
            ]
          },
          {
            "name": "Trader",
            "price": "5001 $ - 15000 $",
            "features": [
              "10 sessions de trading et d'éducation avec un analyste principal",
              "Accès à plus de 100 actifs",
              "3 signaux VIP",
              "Premier retrait gratuit"
            ]
          },
          {
            "name": "Argent",
            "price": "15001 $ - 50000 $",
            "features": [
              "Sessions de trading et d'éducation illimitées avec un analyste principal",
              "Accès à plus de 200 actifs",
              "5 signaux VIP",
              "5 retraits gratuits"
            ]
          }
        ],
        "advanced": [
          {
            "name": "Or",
            "price": "50001 $ - 150000 $",
            "features": [
              "Sessions de trading et d'éducation illimitées avec un analyste principal",
              "Accès à plus de 200 actifs",
              "5 signaux VIP par semaine",
              "10 retraits gratuits"
            ]
          },
          {
            "name": "Platine",
            "price": "150001 $ - 500000 $",
            "features": [
              "Sessions de trading et d'éducation illimitées avec un analyste principal",
              "Accès à plus de 200 actifs",
              "10 signaux VIP par semaine",
              "Retraits gratuits illimités"
            ]
          },
          {
            "name": "Diamant",
            "price": "500001 $ - 1000000 $",
            "features": [
              "Sessions de trading et d'éducation illimitées avec un analyste principal",
              "Accès à plus de 200 actifs",
              "Signaux VIP illimités",
              "Retraits gratuits illimités",
              "10 retraits express gratuits",
              "Effets de levier plus élevés sur tous les actifs",
              "Services de conseiller financier"
            ]
          }
        ]
      }
    }
  }
  const pricingData = data[language]
  return (
    <div className="trade-app section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center">
              <h2>{selectedLanguage.landing.AccountTypesTitle}</h2>
            </div>
          </div>
        </div>
        <div className="row align-items-end">
          <div className="btn-group" role="group" aria-label="Switch buttons">
            <button
              type="button"
              className={`btn ${
                activeContainer === "standard"
                  ? "btn-primary active"
                  : "btn-secondary"
              }`}
              onClick={switchToStandard}
            >
              {selectedLanguage.landing.Standard}
            </button>
            <button
              type="button"
              className={`btn ${
                activeContainer === "advanced"
                  ? "btn-primary active"
                  : "btn-secondary"
              }`}
              onClick={switchToAdvanced}
            >
              {selectedLanguage.landing.Advanced}
            </button>
          </div>

          {activeContainer === "standard" && (
            <div
              className={`row mt-3 fade ${
                activeContainer === "standard" ? "show" : ""
              }`}
              id="standardContainer"
            >
              {pricingData.plans.basic.map((plan) => (
                <div className="col-xl-4 col-lg-4 col-md-12" key={plan.name}>
                  <div className="card trade-app-content">
                    <div className="card-body">
                      <h4 className="card-title">{plan.name}</h4>
                      <h6>{plan.price}</h6>
                      <ul>
                        {plan.features.map((feature) => (
                          <li key={feature}>{feature}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {activeContainer === "advanced" && (
            <div
              className={`row mt-3 fade ${
                activeContainer === "advanced" ? "show" : ""
              }`}
              id="advancedContainer"
            >
              {pricingData.plans &&pricingData.plans.advanced.map((plan) => (
                <div className="col-xl-4 col-lg-4 col-md-12" key={plan.name}>
                  <div className="card trade-app-content">
                    <div className="card-body">
                      <h4 className="card-title">{plan.name}</h4>
                      <h6>{plan.price}</h6>
                      <ul>
                        {plan.features.map((feature) => (
                          <li key={feature}>{feature}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <Link to="/signup" className="btn btn-secondary">
            {selectedLanguage.landing.JoinNow}
        </Link>
      </div>
    </div>
  );
};
export default PricingPlans;
