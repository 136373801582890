import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { apiUrl } from "../../config";
import { toast } from "react-toastify";
import Header1 from "../layout/header1";
import Footer1 from "../layout/footer1";
import { languages } from "../../languages";
import { LanguageContext } from "../../languagesContext";
function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);
  const selectedLanguage = languages[language]

  const togglePasswordVisibility = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    } else {
      setPasswordType("password");
    }
  };
  const handleClick = (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast.error("Please provide email end password");
      return;
    }
    const body = {
      email,
      password,
    };
    const redirectToAnotherPage = (url, token) => {
      const href = `${url}/?token=${token}`;
      window.location.replace(href);
    };
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const endpoint = "/login";
    const raw = JSON.stringify(body);
    const url = apiUrl + endpoint;
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setLoading(true);
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.token) {
          setLoading(false);
          const prodUrl = result.account_uri
          redirectToAnotherPage(prodUrl, result.token);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  return (
    <>
      <Header1 />
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="mini-logo text-center my-5">
                <Link to={"./"}>
                  <img src={require("../../images/logo.png")} style={{maxWidth:'120px'}} alt="logo" />
                </Link>
              </div>
              <div className="auth-form card">
                <div className="card-header justify-content-center">
                  <h4 className="card-title">{selectedLanguage.rest.SignIn}</h4>
                </div>
                <div className="card-body">
                  <form className="signin_validate">
                    <div className="mb-3">
                      <label>{selectedLanguage.rest.Email}</label>
                      <input
                        type="text"
                        name="email"
                        placeholder={selectedLanguage.rest.Email}
                        id="email"
                        className="form-control"
                        required
                        onChange={handleEmailChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label>{selectedLanguage.rest.Password}</label>
                      <div style={{ position: "relative" }}>
                        <input
                          type={passwordType}
                          name="password"
                          id="password"
                          placeholder={selectedLanguage.rest.Password}
                          className="form-control"
                          required
                          onChange={handlePasswordChange}
                        />
                        <i
                          id="eyeIcon"
                          className="fa fa-eye"
                          style={{
                            color: "green",
                            position: "absolute",
                            top: "15px",
                            right: "15px",
                            zIndex: "100",
                            cursor: "pointer",
                          }}
                          onClick={togglePasswordVisibility}
                        ></i>
                      </div>
                    </div>
                    <div className="text-center" onClick={handleClick}>
                      <button className="btn btn-success">
                        {loading ? "Loading" : "Sign in"}
                      </button>
                    </div>
                  </form>
                  <div className="new-account mt-3">
                    <p>
                    {selectedLanguage.landing.DontHaveAccount}
                      <Link className="text-primary" to={"./signup"}>
                      {" "}{selectedLanguage.rest.SignUp}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer1 />
    </>
  );
}

export default Signin;
